import React from 'react'
import './GunShop.css'
import { Link } from 'react-router-dom'
import './OverallStyleSheet.css'

function ServiceOptions() {
  return (
    <div className='ServOpt'>
        <div className='services'>
        <Link to="/Services" style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}><h3>Modifications and Styling</h3></Link>
        </div>
        <div className='gun-option'>
        <Link to="/GunList" style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}><h3>Firearms for Sale</h3></Link>
        </div>
    </div>
  )
}

export default ServiceOptions