import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PartElement from '../Components/PartElement';
import Navi from '../Components/Navi';
import axios from 'axios';
import Footer from '../Components/Footer';
import '../Components/OverallStyleSheet.css';
import WebsitePreview from '../Components/WebsitePreview';

const PartsPage = () => {
  const [partsObjects, setPartsObjects] = useState([]);
  const url = 'https://www.gunbroker.com/Miscellaneous/search?Sort=13&IncludeSellers=1047293&PageSize=96'

  useEffect(() => {
    axios.get('https://combat-customs-1968e5d62644.herokuapp.com/api/contentful/parts') // Update the endpoint
      .then(response => {
        setPartsObjects(response.data);
        console.log(response.data);
      })
      .catch(error => {
        console.error('Error fetching data from backend:', error.message);
      });
  }, []);

  return (
    <div className='body'>
      <Navi />
      {/*<div className="GunList">
      {partsObjects.map((partObject, index) => (
        <PartElement key={index} partObject={partObject} />
      ))}
      </div>*/}
      <div className='HomePage'>
      <h1 style={{
        width: '100%', textAlign:'center'
      }}>Coming Soon!</h1>
      <br />
      <Link to={url}><p style={{
        width: '100%', textAlign:'center'
      }}>Click here to see products and firearms listed by Combat Customs TX via Gunbroker.com</p></Link>
      <WebsitePreview url={url} />
      </div>
      <Footer />
    </div>
  );
};

export default PartsPage;


