import React from 'react';

const WebsitePreview = ({ url }) => {
  return (
    <div style={{ width: '100%', height: '500px', cursor: 'pointer' }}>
      <iframe src={url} title="Website Preview" frameborder="0" scrolling="auto" style={{ width: '100%', height: '100%' }}></iframe>
    </div>
  );
};

export default WebsitePreview;