import React, { useState, useEffect } from 'react';
import GunElement from '../Components/GunElement';
import Navi from '../Components/Navi';
import axios from 'axios';
import Footer from '../Components/Footer';
import { useParams, Link } from 'react-router-dom';
import '../Components/OverallStyleSheet.css';
import WebsitePreview from '../Components/WebsitePreview';

const GunList = () => {
  const [gunObjects, setGunObjects] = useState([]);
  const { urlID } = useParams();
  const url = 'https://www.gunbroker.com/Guns-Firearms/search?Sort=13&includeSellers=1047293&PageSize=96';

     useEffect(() => {
      axios.get('https://combat-customs-1968e5d62644.herokuapp.com/api/contentful/firearms')
      .then(response => {
        setGunObjects(response.data);
        console.log(gunObjects);
      })
      .catch(error => {
        console.error('Error fetching data from backend:', error.message);
      });
    }, [])

  return (
    <div className='body'>
      <Navi />
      {/*<div className="GunList">
      {gunObjects.map((gunObject) => (
        <GunElement key={gunObject.id} gunObject={gunObject} id={urlID} />
      ))}
      </div>*/}
      <div className='HomePage'>
      <h1 style={{
        width: '100%', textAlign:'center'
      }}>Coming Soon!</h1>
      <br />
      <Link to={url}><p style={{
        width: '100%', textAlign:'center', cursor: 'pointer'
      }}>Click here to see products and firearms listed by Combat Customs TX via Gunbroker.com</p></Link>
      <WebsitePreview url={url} />
      </div>
      <Footer />
    </div>
  );
};

export default GunList;


